import React, { useState, useEffect } from "react";
import Axios from 'axios';
import Helmet from "react-helmet";
import { ToastContainer, toast } from 'react-toastify';
import mixpanel from "mixpanel-browser";

import Loader from "../../components/Loader"
import LogoImg from "../../images/shw-side-logo.svg"
import CallImg from "../../images/call.svg"
import RefreshImg from "../../images/refresh.svg"
import CallWhiteImg from "../../images/icon-phone-white.svg"

import HouseImg from "../../images/house.svg"
import HouseSelectedImg from "../../images/house-selected.svg"
import CondoImg from "../../images/condo.svg"
import CondoSelectedImg from "../../images/condo-selected.svg"
import CoOpImg from "../../images/co-op.svg"
import CoOpSelectedImg from "../../images/co-op-selected.svg"
import ElectricalImg from "../../images/electrical.svg"
import ElectricalSelectedImg from "../../images/electrical-selected.svg"
import AirconditionerImg from "../../images/airconditioner.svg"
import AirconditionerSelectedImg from "../../images/airconditioner-selected.svg"
import PipeImg from "../../images/pipe.svg"
import PipeSelectedImg from "../../images/pipe-selected.svg"
import FurnaceImg from "../../images/furnace.svg"
import FurnaceSelectedImg from "../../images/furnace-selected.svg"
import OtherImg from "../../images/other.svg"
import OtherSelectedImg from "../../images/other-selected.svg"
import OvenImg from "../../images/oven.svg"
import OvenSelectedImg from "../../images/oven-selected.svg"
import WasherImg from "../../images/washer-dryer.svg"
import WasherSelectedImg from "../../images/washer-dryer-selected.svg"
import WaterImg from "../../images/water-heater.svg"
import WaterSelectedImg from "../../images/water-heater-selected.svg"
import RefrigeratorImg from "../../images/refrigerator.svg"
import RefrigeratorSelectedImg from "../../images/refrigerator-selected.svg"

import 'react-toastify/dist/ReactToastify.css';
import "./styles.scss";

const LEAD_ID = 'new-affiliate-2021';

const axios = Axios.create();
axios.defaults.baseURL = process.env.GATSBY_API_URL;

mixpanel.init(process.env.GATSBY_MIXPANEL_TOKEN);

const STEPS = [
  {
    type: 'radio',
    direction: 'vertical',
    title: 'Are you closing on a new home or buying for your existing home?',
    options: [
      {
        value: 'Closing on a new home',
        label: 'Closing on a new home'
      },
      {
        value: 'Buying for existing home',
        label: 'Buying for existing home'
      },
      {
        value: 'Other',
        label: 'Other'
      },
    ]
  },
  {
    type: 'radio',
    direction: 'horizontal',
    title: 'What type of property is it?',
    options: [
      {
        value: 'House',
        label: 'House',
        image: HouseImg,
        selectedImg: HouseSelectedImg
      },
      {
        value: 'Condo',
        label: 'Condo',
        image: CondoImg,
        selectedImg: CondoSelectedImg
      },
      {
        value: 'Co-op',
        label: 'Co-op',
        image: CoOpImg,
        selectedImg: CoOpSelectedImg
      },
    ]
  },
  {
    type: 'radio',
    direction: 'vertical',
    title: 'Is it your primary residence?',
    options: [
      {
        value: 'Yes',
        label: 'Yes'
      },
      {
        value: 'No',
        label: 'No'
      },
    ]
  },
  {
    type: 'radio',
    direction: 'vertical',
    title: 'What’s the size of your home (in sq. ft.)?',
    options: [
      {
        value: 'Under 5,000 squre feet',
        label: 'Under 5,000 squre feet'
      },
      {
        value: 'Over 5,000 square feet',
        label: 'Over 5,000 square feet'
      },
    ]
  },
  {
    type: 'radio',
    direction: 'vertical',
    title: 'Do you currently have a home warranty?',
    options: [
      {
        value: 'Yes',
        label: 'Yes'
      },
      {
        value: 'No',
        label: 'No'
      },
    ]
  },
  {
    type: 'checkbox',
    direction: 'horizontal wrap',
    title: 'Which of these systems would you like covered?',
    options: [
      {
        value: 'Electrical',
        label: 'Electrical',
        image: ElectricalImg,
        selectedImg: ElectricalSelectedImg
      },
      {
        value: 'Air Conditioner',
        label: 'Air Conditioner',
        image: AirconditionerImg,
        selectedImg: AirconditionerSelectedImg
      },
      {
        value: 'Plumbing',
        label: 'Plumbing',
        image: PipeImg,
        selectedImg: PipeSelectedImg
      },
      {
        value: 'Furnace',
        label: 'Furnace',
        image: FurnaceImg,
        selectedImg: FurnaceSelectedImg
      },
      {
        value: 'Other/Not Sure',
        label: 'Other/Not Sure',
        image: OtherImg,
        selectedImg: OtherSelectedImg
      },
    ]
  },
  {
    type: 'radio',
    direction: 'vertical',
    title: 'Are any of the appliances in your home older than 7 years?',
    options: [
      {
        value: 'Yes',
        label: 'Yes'
      },
      {
        value: 'No',
        label: 'No'
      },
      {
        value: 'I’m not sure',
        label: 'I’m not sure'
      },
    ]
  },
  {
    type: 'checkbox',
    direction: 'horizontal wrap',
    title: 'Which appliances would you like to cover?',
    options: [
      {
        value: 'Oven, Stove, Range',
        label: 'Oven, Stove, Range',
        image: OvenImg,
        selectedImg: OvenSelectedImg
      },
      {
        value: 'Washer & Dryer',
        label: 'Washer & Dryer',
        image: WasherImg,
        selectedImg: WasherSelectedImg
      },
      {
        value: 'Water Heater',
        label: 'Water Heater',
        image: WaterImg,
        selectedImg: WaterSelectedImg
      },
      {
        value: 'Refrigerator',
        label: 'Refrigerator',
        image: RefrigeratorImg,
        selectedImg: RefrigeratorSelectedImg
      },
      {
        value: 'Other/Not Sure',
        label: 'Other/Not Sure',
        image: OtherImg,
        selectedImg: OtherSelectedImg
      },
    ]
  },
]

const Questionnaire = (props) => {
  const [steps, setSteps] = useState(STEPS);
  const [currentStep, setCurrentStep] = useState(0);
  const [currentValue, setCurrentValue] = useState();
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const step = steps[currentStep];
  const { type, direction, title, options } = step;

  const handleNext = (nextStep) => () => {
    if (nextStep > steps.length || nextStep < 0) {
      return
    }

    const params = new URLSearchParams(props.location.search);

    let response = ''
    for(let i=0; i<= currentStep; i++) {
      response += `${steps[i].title}: ${steps[i].type === 'radio' ? answers[i] : answers[i].join(',')}`
      if (i<currentStep) {
        response += '\n'
      }
    }

    axios.put(`/v2/customers`, {
      customer: {
        questionnaire_responses: response
      },
      customer_id: params.get('userid')
    }).then((res) => {
      if (res && res.data && res.data.error) {
        toast.error(res.data.error, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        if (nextStep === steps.length) {
          toast.success('Submitted successfully!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          goToPricing(false)()
        } else {
          mixpanel.track(`Answered the question: ${title}`, {
            version: LEAD_ID,
            affiliateCode: params.get('affiliateCode')
          });
          setCurrentValue(answers[nextStep] || (steps[nextStep].type === 'radio' ? '' : []))
          setCurrentStep(nextStep)
        }
      }
    }).catch((error) => {
      console.log(error);
      toast.error("Something went wrong!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  }

  const handleChange = (e) => {
    const value = e.target.value;
    if (type === 'radio') {
      setAnswers({
        ...answers,
        [currentStep]: value,
      })
      setCurrentValue(value)
    }
    if (type === 'checkbox') {
      const checked = e.target.checked
      let values = currentValue || []
      if (checked) {
        values.push(value)
      } else {
        values = values.filter(v => v !== value)
      }
      setAnswers({
        ...answers,
        [currentStep]: [...values],
      })
      setCurrentValue([...values])
    }
  }

  const goToPricing = skip => () => {
    const params = new URLSearchParams(props.location.search);
    mixpanel.track(`${skip ? 'Skip' : 'Complete'} the questionnaire`, {
      version: LEAD_ID,
      affiliateCode: params.get('affiliateCode')
    });
    let url = `${process.env.GATSBY_REDIRECT_URL}/purchase?customer_id=${params.get('userid')}&newversion=${LEAD_ID}`
    if (!skip) {
      url = url + `&completed_questionnaire=true`
    }
    window.location.replace(url)
  }

  const refresh = () => {
    setCurrentValue(steps[0].type === 'radio' ? '' : [])
    setCurrentStep(0)
  }

  const affiliate_phone_number = data.affiliate_phone_number || '(855) 267-3532';

  useEffect(() => {
    let params = new URLSearchParams(props.location.search);
    const discountCode = params.get('affiliateCode');

    if (discountCode) {
      axios.get(`/v2/partners/${discountCode}`)
        .then((response) => {
          setLoading(false);
          setData(response.data.partner);
        })
        .catch((error) => {
          setLoading(false);
          console.log('partner api error', error);
        })
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return (
      <div className="loader-container">
        <Loader size={150} color="#1F497D" />
      </div>
    )
  }

  return (
    <div className="questionnaire-root">
      <Helmet>
        <title>Select Home Warranty</title>
      </Helmet>

      <header id="header">
        <div className="container">
          <div className='flex header-top-row'>
            <a href="https://selecthomewarranty.com" target="_blank" rel="noreferrer" className="header-brand">
              <img src={LogoImg} alt="Select Home Warranty" className="header-logo" />
            </a>
          </div>

          <div className="header-cta-mobile">
            <div className="header-contact">
              <a href={`tel:${affiliate_phone_number && affiliate_phone_number.replace(/\D/g,'')}`}><img src={CallImg} alt="Call" className="call-img" /></a>
            </div>
          </div>

          <div className="header-cta">
            <div className="refresh-root">
              <button className="refresh-btn" onClick={refresh}>
                <img src={RefreshImg} alt="Refresh" />
              </button>
            </div>
            <div className="header-contact" style={{flexDirection: 'column'}}>
              {/* <img src={CallImg} alt="Call" className="call-img" /> */}
              <div className="call-des">Want to speak with an agent?</div>
              <span className="header-phone"><a href={`tel:${affiliate_phone_number && affiliate_phone_number.replace(/\D/g,'')}`}>{affiliate_phone_number}</a></span>
            </div>
          </div>
        </div>
      </header>

      <div className="call-now">
        <a href={`tel:${affiliate_phone_number && affiliate_phone_number.replace(/\D/g,'')}`}>
          <img src={CallWhiteImg} alt="Call now for a free quote" />
        </a>
      </div>

      <div className="questionnaire">
        <div className="progress">
          <div className="filled" style={{ width: `${100 * currentStep / (steps.length - 1)}%` }}></div>
        </div>

        <div className="q-body">
          <div className="step">
            <div className="title">
              {title}
            </div>
            <div className={`options ${direction}`}>
              {options.map((option, index) => (
                <div className="option" key={index} onClick={() => {
                  if (type === 'radio') {
                    handleChange({
                      target: {
                        value: option.value
                      }
                    })
                  }
                }}>
                  <input 
                    type={type}
                    id={`${currentStep}-${index}`}
                    name={currentStep}
                    value={option.value}
                    onChange={handleChange} 
                    checked={type === 'radio' ? option.value === currentValue : currentValue.includes(option.value)}
                  />
                  <label htmlFor={`${currentStep}-${index}`}>
                    {option.image && (
                      <img src={(type === 'radio' ? option.value === currentValue : currentValue.includes(option.value)) ? option.selectedImg : option.image} alt="" />
                    )}
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
    
          <div className="actions">
            {currentStep < steps.length - 1 && (
              <button onClick={handleNext(currentStep+1)}>Next</button>
            )}
            {currentStep === steps.length - 1 && (
              <button onClick={handleNext(currentStep+1)} className="quote">Get My Quote</button>
            )}
            {currentStep > 0 && (
              <button onClick={handleNext(currentStep-1)} className="back underline">Back</button>
            )}
            <button onClick={goToPricing(true)} className="skip underline">Skip to pricing</button>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  )
}

export default Questionnaire
